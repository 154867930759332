.mainbar {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 20px 15px;
  background: #fff;
  box-shadow: 0px 7px 15px rgba(121, 121, 186, 0.1);
  margin-bottom: 25px;

  .box {
    text-align: center;
    padding: 0 10px;

    a {
      text-decoration: none;
    }

    &.user {
      display: none;
    }

    &.search {
      text-align: right;
      flex: 1;
      font-size: 24px;
    }
  }

  .menuBtn {
    display: inline-block;
    font-size: 28px;
    color: #7979BA;
    cursor: pointer;
    transition: all 0.35s ease-in-out;

    &.opened {
      transform: rotateZ(90deg);
    }
  }

  .scanTickets {
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon {
      font-size: 28px;
      color: #B5557E;
      padding: 0 10px;
    }
    .text {
      font-size: 18px;
      color: #B5557E;
    }
  }
}