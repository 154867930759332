.textInput {
  position: relative;
  width: 100%;
  padding: 5px 0;
  margin: 5px 0;

  .fieldLabel {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 18px;
    text-align: left;
    color: #9e9ed5;
    transition: all 0.35s ease-in-out;

    &.content {
      font-size: 12px;
      color: #7979ba;
      top: 0;
    }
  }

  input {
    width: 100%;
    padding: 10px;
    font-size: 18px;
    border: none;
    border-bottom: 2px solid #9e9ed5;
    outline: none;
    color: #9e9ed5;
  }
}

.checkBoxWrap {
  display: flex;
  align-items: center;

  .checkBox {
    position: relative;
    display: inline-block;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 20px;
    height: 20px;

    .checkmark {
      position: relative;
      height: 100%;
      width: 100%;
      background-color: transparent;
      border-radius: 5px;
      border: 2px solid #7979ba;

      span {
        color: #7979ba;
        position: absolute;
        font-size: 18px;
        top: -3px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .label {
    color: #b5557e;
    cursor: pointer;
    padding-right: 5px;
  }
}

.button {
  display: inline-block;
  padding: 10px 0;

  button {
    padding: 10px 25px;
    text-align: center;
    color: #fff;
    border-radius: 10px;
    background: #7979ba;
    transition: all 0.35s ease-in-out;
    font-weight: bold;
    font-size: 18px;
    border: none;
    outline: none;
    cursor: pointer;
    min-width: 150px;

    &:hover {
      background: #9e9ed5;
    }

    &:disabled {
      opacity: 0.5;
      cursor: auto;
    }
  }
}

.sectionTitle {
  font-family: 'Nunito', sans-serif;
  font-weight: bold;
  font-size: 20px;
  color: #7979ba;
  margin-bottom: 15px;
  width: 100%;
}

.pageTitle {
  display: flex;
  border-bottom: 1px solid #7979BA;
  padding: 10px 0;
  margin-bottom: 20px;
  font-family: 'Nunito', sans-serif;
  font-weight: bold;
  font-size: 22px;
  color: #7979ba;
  width: 100%;

  .icon {
    padding-right: 10px;
  }

  .title {

  }
}

@keyframes animateMenu {
  0% {left: -900px;}
  100% {left: 0;}
}
.sideMenu {
  position: absolute;
  top: 0;
  left: -900px;
  width: fit-content;
  height: 100vh;
  padding: 200px 0 0;
  background: #7979BA;
  transition: all 0.35s ease-in-out;

  &.open {
    left: 0;
    //animation: animateMenu 0.35s forwards ease-in-out;
  }
  a {
    color: #fff;
    text-decoration: none;
  }
  .menuLink {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    transition: all 0.35s ease-in-out;
    .icon {
      text-align: center;
      width: 35px;
      font-size: 24px;
    }
    .menuItem {
      color: #fff;
      font-size: 18px;
      padding-left: 20px;
    }
    &:hover {
      background: linear-gradient(to right, #9e9ed5 0%, rgba(252, 252, 255, 0.75) 100%);
    }
  }
}

.inPageSectionTitle {
  font-family: 'Nunito', sans-serif;
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  color: #9e9ed5;
  padding: 5px;
  border-bottom: 1px solid #9e9ed5;
  width: 100%;
  margin-bottom: 10px;
}

.eventTitle {
  width: 100%;
  border-bottom: 1px solid #7979BA;
  font-weight: bold;
  color: #7979BA;
  font-size: 16px;
  padding: 5px 0;
  .eventType {
    color: #b5557e;
  }
}

.selectList {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  flex: 1;
  cursor: pointer;

  .selectHeader {
    position: absolute;
    font-weight: bold;
    color: #becdd1;
    transition: all 0.25s ease;
    pointer-events: none;
    top: -10px;
    right: 5px;
    font-size: 10px;
  }

  &:focus-within {
    .selectHeader {
      color: #4a7581;
    }
  }

  input {
    width: 100%;
    padding: 10px;
    font-size: 18px;
    border: none;
    border-bottom: 2px solid #9e9ed5;
    outline: none;
    color: #9e9ed5;
    cursor: pointer;

    &.hiddenInput {
      display: none;
    }
  }

  .selectArrow {
    position: absolute;
    right: 3px;
    top: 10px;
    cursor: pointer;
    color: #9e9ed5;
  }

  .selectOptions {
    display: block;
    color: #9e9ed5;
    position: absolute;
    width: 100%;
    min-width: 150px;
    left: 0;
    top: 100%;
    background: #fff;
    border-left: 3px solid #7979BA;
    padding: 10px 15px;
    box-shadow: 2px 2px 5px rgba(160, 160, 160, 0.35);
    z-index: 10;

    .optionLine {
      position: relative;
      cursor: pointer;
      color: #7979BA;
      text-align: left;

      &:hover {
        color: #b5557e;
      }
    }
  }

  &:hover {
    @keyframes animateArrow {
      0% { transform: translateY(0); }
      50% { transform: translateY(15%); }
    }
    .selectArrow {
      color: #607690;
      animation: animateArrow 0.75s infinite ease-in-out;

      &.noAnimation {
        animation: none;
      }
    }
  }

  &.red {
    input {
      width: 100%;
      padding: 3px 5px;
      font-size: 14px;
      border: none;
      border-bottom: 2px solid #b5557e;
      outline: none;
      color: #b5557e;
      cursor: pointer;
    }

    .selectArrow {
      position: absolute;
      right: 3px;
      top: 0px;
      cursor: pointer;
      color: #b5557e;
    }

    .selectOptions {
      display: block;
      color: #b5557e;
      position: absolute;
      width: 100%;
      min-width: 150px;
      left: 0;
      top: 100%;
      background: #fff;
      border-left: 3px solid #b5557e;
      padding: 10px 15px;
      box-shadow: 2px 2px 5px rgba(160, 160, 160, 0.35);
      z-index: 10;

      .optionLine {
        color: #b5557e;

        &:hover {
          color: #851846;
        }
      }
    }
    &:hover {
      .selectArrow {
        color: #ee85b1;
      }
    }
  }
}

.fileUploader {
  display: block;
  position: relative;

  .selectFile {
    width: 100%;
    position: relative;
    display: flex;
    padding: 10px;
    font-size: 18px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-bottom: 2px solid #9e9ed5;

    .label {
      padding-right: 10px;
    }

    .uploadBtn {
      font-size: 24px;
    }
    &:hover {
      .uploadBtn {
        color: #7979BA;
      }
    }
  }

  .previewImg {
    display: block;
    text-align: center;
    margin: 10px 0;

    img {
      object-fit: cover;
      max-height: 250px;
      max-width: 100%;
    }
  }
}

.inPageLink {
  color: #7979BA;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    color: #b5557e;
    text-decoration: underline;
  }
}

textarea {
  width: 100%;
  min-height: 150px;
  border: 1px solid #9e9ed5;
  padding: 10px;
  font-size: 14px;
  color: #9e9ed5;
}

.inlineLinks {
  list-style: none;
  li {
    display: inline-block;
    padding: 0 5px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}

.loader-component {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #7979BA;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
}

.toggleButton2 {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .option {
    flex: 1;
    display: flex;
    align-items: center;
    background: #607690;
    color: #fff;

    .icon {
      padding: 5px;
      font-size: 18px;
    }

    &.chosen {
      background: #57AF3D;
      font-size: 1rem;
    }
  }
}

.transactionBlock {
  width: 100%;
  padding: 10px;
  border: 1px solid #57AF3D;
  border-top: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.suggestions-list {
  margin: 10px 0;
  div {
    cursor: pointer;
    font-weight: 500;
  }
}

.general-msg {
  position: absolute;
  top: 100%;
  right: 0;
  display: block;
  padding: 10px;
  width: fit-content;
  background: #fff;
  border: 1px solid #7979BA;
  color: #7979BA;
  font-weight: bold;
  font-family: 'Nunito', sans-serif;
  animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-in-right {
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.popup-wrapper {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
.popupBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.25);
  z-index: 1001;
}

.popup-wrap {
  width: 100%;
  padding: 20px;
  background: #fff;
  z-index: 2000;

  .popup-title {
    display: block;
    padding: 5px 0;
    border-bottom: 2px solid #7979BA;
    color: #7979BA;
    font-family: 'Nunito', sans-serif;
    font-size: 16px;
    font-weight: bold;
  }

  .popup-msg {
    padding-top: 15px;
    font-size: 16px;
    font-family: 'Nunito', sans-serif;
    color: #7979BA;
  }

  .popup-confirm {
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .btn {
      padding: 0 5px;
    }
  }
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  div {
    padding-right: 5px;
    &:last-of-type {
      padding-right: 0;
    }
  }
}

.cancel-btn {
  background: #fff;
  color: #7979BA;
  border: none;
  outline: none;
  border-bottom: 2px dotted #7979BA;
  cursor: pointer;

  &:hover {
    color: #851846;
    border-color: #851846;
  }
}