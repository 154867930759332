.notFound {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75%;
    @media screen and (min-width: 1000px) {
      width: 50%;
      padding-top: 100px;
    }

    .text {
      .code {
        align-self: flex-start;
        font-size: 42px;
        font-weight: bold;
        color: #b5557e;
      }
      text-align: center;
      font-size: 32px;
      color: #7979BA;

      img {
        display: block;
        width: 100%;
      }
    }
  }
}