a {
  text-decoration: none;
}

.scannerHeader {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  color: #b5557e;
  font-size: 18px;
  margin-bottom: 25px;
  font-weight: bold;

  .icon {
    padding-right: 15px;
    font-size: 28px;
  }
}

.scannerEventRow {
  position: relative;
  display: block;
  width: 100%;
  margin: 10px 0;
  padding: 5px;
  background: #fff;
  border: 1px solid #9e9ed5;
  color: #9e9ed5;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 0px 10px rgba(121, 121, 186, 0.35);
  }

  .eventDetails {
    display: flex;
    align-items: center;
    .eventTime {
      padding: 10px;
      font-weight: bold;
      color: #b5557e;
      border-right: 1px solid #9e9ed5;
    }
    .eventType {
      text-align: center;
      font-size: 10px;
      opacity: 0.5;
    }

    .eventName {
      padding: 10px;
    }
  }
}