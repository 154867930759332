@font-face {
  font-family: 'Oranienbaum';
  src: local('Oranienbaum'), url('./../fonts/Oranienbaum-Regular.ttf') format('truetype');
}

@font-face {
  font-family: "Nunito";
  src: url("./../fonts/Nunito/Nunito-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Nunito";
  src: url("./../fonts/Nunito/Nunito-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Nunito";
  src: url("./../fonts/Nunito/Nunito-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Nunito";
  src: url("./../fonts/Nunito/Nunito-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
}

body {
  color: #9e9ed5;
}

.login {
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .loginContainer {
      font-size: 18px;
      text-align: left;
      width: 100%;
      padding: 20px;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.16);

      .title {
        font-weight: bold;
        padding: 10px 0;
        border-bottom: 1px solid #7979BA;
        color: #7979ba;
      }

      .formFields {
        padding: 20px 0;
      }

      .rememberMe {
        text-align: right;
      }
    }
  }

}

.rightAlign {
  position: relative;
  text-align: right;
}