.fixedHeader {
  position: fixed;
  width: 100%;
  z-index: 100;
}

.header {
  position: relative;
  z-index: 110;
  background: #7979BA;
  .kislov {
    text-align: center;
    padding: 20px;
    font-size: 42px;
    font-family: Oranienbaum;
    color: #fff;
  }
}

.footer {
  width: 90%;
  margin: 20px auto 0;
  padding: 10px 0;
  font-size: 10px;
  color: #9e9ed5;
  border-top: 1px solid #9e9ed5;
  text-align: center;
}

.page {
  padding: 188px 20px 5%;

  &>div {
    display: flex;
    flex-wrap: wrap;
  }

  .orders {
    margin-top: 25px;
  }
}

.singleEventSummary {
  position: relative;
  width: 100%;
  padding: 20px 10px;

  @media screen and (min-width: 768px) {
    width: 50%;
  }
  @media screen and (min-width: 1200px) {
    width: 33.33%;
  }

  .wrapEvent {
    width: 100%;
    height: 185px;
    border-radius: 25px;
    background: #fff;
    box-shadow: 0px 0px 10px rgba(121, 121, 186, 0.15);
    display: flex;
    transition: all 0.25s ease-in-out;

    &:hover {
      transform: scale(1.05);
      z-index: 100;
    }

    .eventImg {
      position: relative;
      width: 130px;
      border-bottom-left-radius: 25px;
      border-top-left-radius: 25px;
      background-size: cover;
      background-position: center;

      .eventType {
        position: absolute;
        bottom: 15%;
        left: 0;
        background: #B5557E;
        color: #fff;
        font-size: 12px;
        padding: 5px 10px;
      }
    }

    .eventDetails {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 10px;

      .eventTitle {
        font-weight: bold;
        font-size: 13px;
        text-align: left;
        color: #7979ba;
        padding: 5px 0;
        border-bottom: 1px solid #7979ba;
      }

      .eventTime {
        text-align: right;
        font-weight: normal;
        font-size: 10px;
        color: #7979ba;
        padding: 3px 0;
      }

      .awaitingApproval {
        font-weight: bold;
        font-size: 12px;
        color: #b5557e;
        padding: 5px 0;
      }

      .eventReport {
        font-size: 12px;
        line-height: 18px;
        color: #7979ba;
        flex: 1;
      }

      .eventFooter {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-top: 1px solid #7979ba;
        padding: 5px 0;

        .footerBox {
          flex: 50%;
          font-weight: bold;
          font-size: 12px;

          .copyLink {
            cursor: pointer;
            &:hover {
              color: #7979BA;
            }
          }

          a {
            text-decoration: none;
            color: #9e9ed5;
            &:hover {
              color: #7979BA;
            }
          }
        }
      }
    }
  }
}

.eventsFilter {
  .filterMenu {
    width: 100%;
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      li {
        display: inline-block;
        padding: 5px;
        cursor: pointer;

        &:hover {
          color: #7979BA;
          text-decoration: underline;
        }
      }
    }
  }
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 25px;
  thead {
   border-bottom: 1px solid #9e9ed5;
    th {
      padding: 5px;
      text-align: left;
    }
  }

  tbody {
    tr {
      td {
        padding: 5px;
        font-size: 14px;
      }

      &:hover {
        background: #f5f5ff;
      }
    }
  }

  &.noHover {
    tbody {
      tr {
        &:hover {
          background: none;
          color: #9e9ed5;
        }
      }
    }
  }
}

.general-alert {
  width: 75%;
  margin: auto;
  padding: 25px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #7979BA;
}

.inpageBlock {
  width: 100%;
  padding: 10px 20px;
  font-size: 0.75rem;

  .title {
    width: 100%;
    border-bottom: 1px solid #7979BA;
    padding: 3px 0;
    margin-bottom: 5px;
    font-weight: bold;
  }
}