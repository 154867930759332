.eventPage {
  .eventImage {
    margin: 15px 0;
    width: 100%;
    height: 200px;
    background-size: cover;
    background-position: center;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
  }

  .eventDetails {
    display: flex;
    flex-direction: column;
    .detailsRow {
      display: flex;
      line-height: 28px;
      justify-content: space-between;

      .title {
        font-size: 16px;
        font-weight: bold;
        color: #9e9ed5;
      }

      .data {
        text-align: right;
        font-weight: normal;
        color: #b5557e;
      }
    }
  }

  .eventDescription {
    margin: 20px 0;

    .titleRow {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .title {
        font-size: 16px;
        font-weight: bold;
        color: #9e9ed5;
      }

      .control {
        text-align: right;
        font-weight: normal;
        color: #b5557e;
      }
    }

    .content {
      padding: 15px 0;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      text-align: left;
      color: #9e9ed5;
    }
  }

  .orders {
    .title {
      font-size: 16px;
      color: #b5557e;
      font-weight: bold;
      border-bottom: 1px solid #9e9ed5;
      padding: 5px 0;
      margin-bottom: 10px;
      width: 100%;
    }

    table {
      td.status {
        &.open {
          font-weight: bold;
          color: #b5557e;
        }

        &.ok, &.paid {
          font-weight: bold;
          text-transform: capitalize;
          color: #57AF3D;
        }
        &.cancelled {
          text-decoration: line-through;
        }
      }
      tr.summary {
        border-top: 1px solid #9e9ed5;
        font-weight: bold;
        &:hover {
          background: none;
        }
      }
    }

    .addNew {
      width: 100%;
    }
  }
}
